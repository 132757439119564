import 'element-plus/es/components/message/style/css';
import { ref, getCurrentInstance, onMounted } from 'vue';
export default {
  name: "App",
  components: {},

  setup() {
    let timer = [];
    onMounted(() => {
      let musicSvg = document.getElementById('music');
      timer.push(setTimeout(() => {
        musicSvg.childNodes.forEach(item => {
          item.style.fill = '#333';
          item.style.stroke = '#fff';
        });
      }, 1200));
      const pList = document.querySelectorAll('.svg_p');
      pList.forEach(p => {
        let len = p.getTotalLength(); // console.log(len,p)

        p.style.setProperty('--l', len); // p.style.fill = '#333333'
      }); // pList
    });

    function clickBtn(e) {
      if (timer.length > 0) {
        timer.forEach(timeItem => {
          clearTimeout(timeItem);
        });
      }

      timer = [];
      const pList = document.querySelectorAll('.svg_p');
      pList.forEach(p => {
        p.style.stroke = '#333'; // console.log(len,p)

        p.style.fill = 'none';
      });
      let curPList = e.target.children;

      for (let i = 0; i < curPList.length; i++) {
        console.log(curPList[i].classList);
        curPList[i].classList.remove('svg_p');
        timer.push(setTimeout(() => {
          curPList[i].classList.add('svg_p');
        }, 0));
        timer.push(setTimeout(() => {
          curPList[i].style.fill = '#333';
          curPList[i].style.stroke = '#fff'; // curPList[i].classList.remove('svg_p')
        }, 1200)); // curPList[i].style.animationPlayState = 'running'
      }
    }

    return {
      clickBtn
    };
  }

};