import request from '../utils/request';
//随机获取1首诗
export const get_shici_random_one = query => {
    
    return new Promise((res,rej)=>{
        request({
            url: '/get_shici_random_one',
            method: 'post',
            params: query
        }).then(data=>{
            if(data.status == 200 && data.data.code == 200){
                res(data.data.result)
            }
            else{
                rej(data)
            }
        });
    })
    return request({
        url: '/get_shici_random_one',
        method: 'post',
        params: query
    });
};
//获取每日1首诗
export const get_shici_one = query => {
    return new Promise((res,rej)=>{
        request({
            url: '/get_shici_one',
            method: 'post',
            params: query
        }).then(data=>{
            if(data.status == 200 && data.data.code == 200){
                res(data.data.result)
            }
            else{
                rej(data)
            }
        });
    })
};