import { get_shici_one, get_shici_random_one } from "@/api/shici";
import { get } from "@vueuse/shared";
import { onMounted, reactive, toRefs, nextTick, onUnmounted } from "vue"; //文本断句

const duanJu = /(?<=[；。？！])\B/g;
/** 动画时间，用于计算文字飞行的速度 */

const aniTime = 2000 / 60;
export default {
  name: "about_name",

  setup() {
    /** 这里是定时器 */
    let timer = null;
    let shici = reactive({
      title: null,
      name: null,
      year: null,
      text: []
    });
    let posList = reactive({
      list: []
    }); //挂载生命周期

    onMounted(() => {
      document.title = '每日诗词';
      get_shici_one().then(res => {
        console.log("请求结果");
        console.log(res);
        shici.text = res.text.split(duanJu);
        shici.name = res.name;
        shici.year = res.year;
        shici.title = res.title; // console.log(shici.text);

        nextTick(txtMoveAni);
      }).catch(err => {
        console.log(err);
      });
    });
    onUnmounted(() => {
      clearInterval(timer);
    }); //获取随机一首诗

    function get_random_one() {
      clearInterval(timer);
      let copy = document.getElementById("copy");
      copy?.remove();
      posList.list = [];
      console.log("切诗");
      get_shici_random_one().then(res => {
        console.log("请求结果");
        console.log(res);
        shici.text = res.text.split(duanJu);
        shici.name = res.name;
        shici.year = res.year;
        shici.title = res.title; // console.log(shici.text);

        nextTick(txtMoveAni);
      });
    }
    /** 文字移动动画 */


    function txtMoveAni() {
      let context = document.getElementById("context");
      context.style.opacity = 0;
      let newContext = context.cloneNode(true);
      newContext.id = 'copy';
      newContext.style.opacity = 255; // Object.assign(newContext,context)

      console.log(newContext);
      let title = newContext.children[0].innerHTML;
      title = title.split('').map(item => {
        return `<span class="moveAni">${item}</span>`;
      });
      newContext.children[0].innerHTML = title.join('');
      let name = newContext.children[1].children[1].innerHTML;
      name = name.split('').map(item => {
        return `<span class="moveAni">${item}</span>`;
      });
      newContext.children[1].children[1].innerHTML = name.join('');
      let year = newContext.children[1].children[0].innerHTML;
      year = year.split('').map(item => {
        return `<span class="moveAni">${item}</span>`;
      });
      newContext.children[1].children[0].innerHTML = year.join('');

      for (let i = 0; i < newContext.children[2].children.length; i++) {
        let txt = newContext.children[2].children[i].innerHTML;
        txt = txt.split('').map(item => {
          return `<span class="moveAni">${item}</span>`;
        });
        newContext.children[2].children[i].innerHTML = txt.join('');
      }

      let moveAniDiv = document.getElementById("moveAniDiv");
      moveAniDiv.appendChild(newContext);
      nextTick(playAni);
    }

    function playAni() {
      let PlistPos = [];
      const pList = document.querySelectorAll('.moveAni'); // console.log(23,pList[0].style)
      // const root = document.querySelectorAll('.root')[0]

      pList.forEach(item => {
        let tarX = Math.round(getElementLeft(item));
        let tarY = Math.round(getElementTop(item));
        let randomX = Math.round(Math.random() * window.innerWidth * 2);
        let randomY = Math.round(Math.random() * window.innerHeight * 2); // root.appendChild(newItem)

        let speedX = Math.round(Math.abs((tarX - randomX) / aniTime));
        let speedY = Math.round(Math.abs((tarY - randomY) / aniTime));
        speedX = speedX < 1 ? 1 : speedX;
        speedY = speedY < 1 ? 1 : speedY;
        let tempPos = {
          tarX,
          tarY,
          value: item.innerHTML,
          speedX,
          speedY,
          curX: randomX,
          curY: randomY
        };
        PlistPos.push(tempPos);
      });
      pList.forEach(item => item.remove());
      posList.list = PlistPos;
      clearInterval(timer);
      timer = setInterval(() => {
        let isChange = false;

        for (let i = 0; i < posList.list.length; i++) {
          let item = posList.list[i]; // let str = JSON.stringify(item)

          if (item.curX != item.tarX) {
            isChange = true;

            if (item.curX - item.tarX > item.speedX) {
              item.curX -= item.speedX;
            } else if (item.curX - item.tarX < -item.speedX) {
              item.curX += item.speedX;
            } else if (item.curX - item.tarX == 0) {} else {
              item.curX = item.tarX;
            }
          }

          if (item.curY != item.tarY) {
            isChange = true;

            if (item.curY - item.tarY > item.speedY) {
              item.curY -= item.speedY;
            } else if (item.curY - item.tarY < -item.speedY) {
              item.curY += item.speedY;
            } else {
              // console.log('ddd')
              item.curY = item.tarY;
            }
          } // console.log(JSON.stringify(PlistPos[i]) == JSON.stringify(posList[i]))

        } // PlistPos.forEach(item=>{
        // })


        if (isChange) {// posList.list = PlistPos
          // console.log('ddd',PlistPos[0])
        } else {
          clearInterval(timer); // moveAniList.forEach(item=>item.remove())

          let copy = document.getElementById("copy");
          copy.remove();
          let context = document.getElementById("context");
          posList.list = [];
          context.style.opacity = 255;
        }
      }, 1000 / 30); // console.log(getElementLeft(pList[0]),getElementTop(pList[0]))
    }

    function getElementTop(element) {
      var actualTop = element.offsetTop; //这是获取元素距父元素顶部的距离

      var current = element.offsetParent; //这是获取父元素

      while (current !== null) {
        //当它上面有元素时就继续执行
        actualTop += current.offsetTop; //这是获取父元素距它的父元素顶部的距离累加起来

        current = current.offsetParent; //继续找父元素
      }

      return actualTop;
    }

    function getElementLeft(element) {
      var actualLeft = element.offsetLeft; //这是获取元素距父元素顶部的距离

      var current = element.offsetParent; //这是获取父元素

      while (current !== null) {
        //当它上面有元素时就继续执行
        actualLeft += current.offsetLeft; //这是获取父元素距它的父元素顶部的距离累加起来

        current = current.offsetParent; //继续找父元素
      }

      return actualLeft;
    }

    return { ...toRefs(shici),
      posList,
      get_random_one
    };
  }

};