// import HelloWorld from "./HelloWorld.vue";
import { ElMessage, ElDrawer } from "element-plus";
import { get_music, music_grade, music_search, music_get_lyric } from "@/api/music";
import { markRaw, onMounted, reactive, toRefs, ref, onDeactivated, nextTick } from "vue";
import { Refresh, Service, Orange } from "@element-plus/icons-vue"; // 引入 Edit 这个 svg组件

export default {
  name: "home_name",
  components: {
    ElDrawer // musicDrawer
    // ElButton,

  },

  setup() {
    //歌词滚动定时器
    let lyricInterval = null;
    /** 搜索值 */

    let searchValue = ref(""); //歌曲播放时间

    let lyricTimer = ref(0); //歌词下标，第多少行（这里有问题，部分歌词单句不止一行）

    let lyricIdx = ref(0);
    /** 按下的坐标 */

    let clickClientX = ref(0); //侧边栏

    const formLabelWidth = "80px"; //侧边数据

    const table = ref(false);
    const loading = ref(false);
    /** 弹出框开关 */

    const dialogTableVisible = ref(false); //歌曲信息

    let songData = reactive({
      /** 是否在播放中 */
      status: false,

      /** 播放的下标 */
      index: 0,

      /** 歌手 */
      singer: null,

      /** 歌名 */
      title: null,

      /** 图片 */
      img: null,

      /** 歌曲源 */
      src: "",

      /** 歌词 */
      lyric: [],

      /** 当前歌单 */
      music_list: [],

      /** 获取歌单 */
      get_music_list: [],

      /** 模式,0列表循环，1单曲循环，2列表随机 */
      mode_idx: 0
    });
    /** 搜索列表 */

    const search_List = reactive({
      list: []
    });
    /** 搜索当前页 */

    const serCurPage = ref(1);
    const pageSize = ref(10);
    const serDisabled = ref(false);
    const serCount = ref(102); //歌曲播放结束，切换歌曲

    function end_music() {
      //判断播放模式
      if (songData.mode_idx == 0) {
        //列表循环
        if (songData.index < songData.music_list.length - 1) {
          songData.index++;
        } else {
          songData.index = 0;
        }

        change_music();
      } else if (songData.mode_idx == 1) {
        //单曲循环
        let music = document.getElementById("audio");
        console.log("单曲循环");
      } else if (songData.mode_idx == 2) {
        //列表随机
        songData.index = Math.floor(Math.random() * songData.music_list.length);
        change_music();
      }

      resetPlayStyle();
      setMusicState(true);
    }
    /**
     * 左右滑动事件
     * @param {*} e 事件
     */


    function huaDong(e) {
      let moveX = e.clientX - clickClientX.value;
      console.log(moveX);

      if (moveX < -80) {
        console.log('在左滑');
      } else if (moveX > 80) {
        console.log('在右滑');
      } else {
        console.log('滑动距离太小');
      }
    }
    /** 根据歌名或者歌手搜索 */


    function searchFn() {
      console.log('开始搜索', searchValue.value);

      if (searchValue.value == '') {
        showMessage('搜索词为空', 'error');
        return;
      }

      let updata = {
        search_value: searchValue.value,
        page_index: serCurPage.value
      };
      music_search(updata).then(res => {
        console.log('开始搜索成功', res.data);
        /** 搜索未完成，这里终端 */
        // return

        dialogTableVisible.value = true;
        search_List.list = res.data.result.list;
        serCount.value = res.data.result.total_num;
        console.log(search_List);
      }).catch(err => {
        console.log('开始搜索成功', err);
      });
    }
    /**
     * 按下事件，记录按下时的坐标
     * @param {*} e 事件
     */


    function anXia(e) {
      clickClientX.value = e.clientX;
    }
    /** 歌曲点赞 */


    function music_zan() {
      console.log(songData.music_list[songData.index].id);
      let updata = {
        music_id: songData.music_list[songData.index].id,
        grade: 1
      };
      music_grade(updata).then(res => {
        console.log('请求点赞成功', res);
      }).catch(err => {
        console.log('请求点赞成功', err);
      });
    }
    /** 歌曲踩 */


    function music_cai() {
      console.log(songData.music_list[songData.index].id);
      let updata = {
        music_id: songData.music_list[songData.index].id,
        grade: 2
      };
      music_grade(updata).then(res => {
        console.log('请求踩成功', res);
      }).catch(err => {
        console.log('请求踩成功', err);
      });
    } //选择播放某一首歌


    function clickPlayMusic(data) {
      console.log(data);
      songData.index = data; // songData.status = true;
      // clearInterval(lyricInterval)

      change_music();
      resetPlayStyle();
      setMusicState(true);
    }
    /** 改变播放当前歌曲的信息 */


    function change_music() {
      console.log(songData.music_list[songData.index]);
      songData.singer = songData.music_list[songData.index]["music_singer"];
      songData.title = songData.music_list[songData.index]["music_name"];
      songData.img = songData.music_list[songData.index]["pic"] ?? 'https://manage1.hej6666.cn/public/musicImg/defalut.jpeg';
      songData.src = songData.music_list[songData.index]["music_src"];
      let updata = {
        music_id: songData.music_list[songData.index].id
      };
      songData.lyric = [];
      music_get_lyric(updata).then(res => {
        if (res.data.result.lyric) {
          songData.lyric = JSON.parse(res.data.result.lyric);
        }
      });
      document.title = songData.singer + " " + songData.title;
      lyricTimer.value = 0;
      lyricIdx.value = 0;
    } //获取新的歌曲列表


    function getNewList() {
      get_music().then(res => {
        console.log("请求结果");
        console.log(res);

        if (res.status == 200) {
          if (res.data.code == 200) {
            songData.music_list = res.data.result.list;
            songData.index = 0;
            change_music();
            resetPlayStyle();
            setMusicState(true);
          }
        }
      });
    } //切换歌曲模式


    function nextModel() {
      if (songData.mode_idx < 2) {
        songData.mode_idx++;
      } else {
        songData.mode_idx = 0;
      }

      if (songData.mode_idx == 0) {
        showMessage("列表循环");
      } else if (songData.mode_idx == 1) {
        showMessage("单曲循环");
      } else if (songData.mode_idx == 2) {
        showMessage("随机播放");
      }
    } //展示消息提示


    function showMessage(msg, type = 'success') {
      ElMessage({
        message: msg,
        type: type,
        center: true,
        offset: 50
      });
    } //挂载生命周期


    onMounted(() => {
      let music = document.getElementById("audio");
      music.pause(); //清除定时器

      clearInterval(lyricInterval); //加载本地音乐数据

      let LOCAL_MUSIC_LIST = localStorage.getItem('LOCAL_MUSIC_LIST'); // console.log('这是LOCAL_MUSIC_LIST', LOCAL_MUSIC_LIST)

      if (LOCAL_MUSIC_LIST) {
        let dataT = JSON.parse(LOCAL_MUSIC_LIST);
        songData.music_list = JSON.parse(LOCAL_MUSIC_LIST);
        change_music();
      } //获取10首随机歌曲
      // get_music().then((res) => {
      //   console.log("请求结果");
      //   console.log(res);
      //   if (res.status == 200) {
      //     if (res.data.code == 200) {
      //       songData.music_list = res.data.result.list;
      //       change_music()
      //     }
      //   }
      // });

    }); // 组件销毁，清楚定时器

    onDeactivated(() => {
      // if(lyricInterval){
      clearInterval(lyricInterval); // }
    }); //双击事件测试

    function dblclick() {
      console.log("开始双击过了");
      alert("开始双击过了");
    } //侧边栏关闭


    const drawerRef = ref();

    const onClick = () => {
      if (drawerRef.value) {
        drawerRef.value.close();
      }
    }; //歌词自动滚动


    function lyricAutoSpin() {
      clearInterval(lyricInterval);
      lyricInterval = setInterval(() => {
        let music = document.getElementById("audio");

        if (!music) {
          return;
        }

        if (songData.lyric.length <= 0) {
          return;
        }

        let lyric_data = document.getElementById("lyric");
        lyricTimer.value = Math.round(music.currentTime * 10);
        songData.lyric.forEach((element, idx) => {
          if (Math.round(element.time * 10) == lyricTimer.value) {
            lyricIdx.value = idx;
            let showIdx = idx < 3 ? 0 : idx - 3; // console.log(songData.lyric[showIdx])

            lyric_data.scrollTop = showIdx * (lyric_data.children[0].offsetHeight + 16);
            console.log(lyric_data.children[0].offsetHeight);
          }
        });
      }, 100);
    }
    /** 改变播放状态以及样式
     * @param bool 设置的播放状态
     */


    function setMusicState(bool) {
      let music = document.getElementById("audio");

      if (bool) {
        nextTick(() => {
          music.play().catch(() => {});
        });
        songData.status = true;
        lyricAutoSpin();

        if (songData.lyric.length > 0) {}
      } else {
        songData.status = false;
        music.pause();
        clearInterval(lyricInterval);
      }
    }
    /** 重置播放样式 */


    function resetPlayStyle() {
      clearInterval(lyricInterval);
      let lyric_data = document.getElementById("lyric"); // console.log(lyric_data.scrollTop)
      // console.log('将第一句歌词置顶')

      lyric_data.scrollTop = 0;
    }
    /** 搜索跳转页面 */


    const handleCurrentChange = val => {
      console.log(`current page: ${val}`);
      serCurPage.value = val;
      searchFn();
    };
    /** 加入歌单 */


    function addMusicList(key) {
      let item = search_List.list[key];
      let LOCAL_MUSIC_LIST = localStorage.getItem('LOCAL_MUSIC_LIST');

      if (!LOCAL_MUSIC_LIST) {
        let tempData = [];
        tempData.push(item); // console.log(tempData)

        localStorage.setItem('LOCAL_MUSIC_LIST', JSON.stringify(tempData));
      } else {
        let itemList = JSON.parse(LOCAL_MUSIC_LIST);
        let isInList = false;
        itemList.forEach(item => {
          if (item.id == search_List.list[key].id) {
            isInList = true;
          }
        });

        if (isInList) {
          showMessage("已经在歌单中了", 'warning');
          return;
        }

        itemList.push(item);
        localStorage.setItem('LOCAL_MUSIC_LIST', JSON.stringify(itemList));
        songData.music_list = itemList;
        showMessage("已加入本地歌单");
      } // console.log(JSON.parse(localStorage.getItem('LOCAL_MUSIC_LIST')))

    }

    function playLast() {
      console.log('播放上一首'); //判断播放模式

      if (songData.mode_idx == 2) {
        //列表随机
        songData.index = Math.floor(Math.random() * songData.music_list.length);
      } else {
        //列表循环
        if (songData.index > 0) {
          songData.index--;
        } else {
          songData.index = songData.music_list.length - 1;
        }
      }

      change_music();
      resetPlayStyle();
      setMusicState(true);
    }

    function playNext() {
      console.log('播放下一首'); //判断播放模式

      if (songData.mode_idx == 2) {
        //列表随机
        songData.index = Math.floor(Math.random() * songData.music_list.length);
      } else {
        //列表循环
        if (songData.index < songData.music_list.length - 1) {
          songData.index++;
        } else {
          songData.index = 0;
        }
      }

      change_music();
      resetPlayStyle();
      setMusicState(true);
    }
    /** 从列表中删除歌曲 */


    function delMusic(idx) {
      console.log('删除歌曲', idx);
      let delData = songData.music_list.splice(idx, 1);
      console.log(delData, delData[0].id);
      let LOCAL_MUSIC_LIST = localStorage.getItem('LOCAL_MUSIC_LIST');

      if (!LOCAL_MUSIC_LIST) {
        console.log('并不在歌单中');
      } else {
        let itemList = JSON.parse(LOCAL_MUSIC_LIST);

        for (let i = 0; i < itemList.length; i++) {
          if (itemList[i].id == delData[0].id) {
            itemList.splice(i, 1);
            break;
          }
        }

        localStorage.setItem('LOCAL_MUSIC_LIST', JSON.stringify(itemList));
      }

      change_music();
      resetPlayStyle();

      if (songData.status) {
        setMusicState(true);
      }
    }

    return { ...toRefs(songData),
      formLabelWidth,
      table,
      loading,
      drawerRef,
      onClick,
      getNewList,
      end_music,
      clickPlayMusic,
      nextModel,
      dblclick,
      lyricTimer,
      lyricIdx,
      huaDong,
      anXia,
      clickClientX,
      music_zan,
      music_cai,
      searchFn,
      searchValue,
      dialogTableVisible,
      search_List,
      serCurPage,
      pageSize,
      serDisabled,
      serCount,
      handleCurrentChange,
      addMusicList,
      playLast,
      playNext,
      delMusic,
      setMusicState
    };
  }

};