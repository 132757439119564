import request from '../utils/request';
//获取所有已发表的博客分类
export const blogClassAll = query => {
    return request({
        url: '/blogClassAll',
        method: 'post',
        // params: query,//这是将参数挂载到url中
        data: query
    });
};

//发表文章请求
export const pub_blog = query => {
    return request({
        url: '/pub_blog',
        method: 'post',
        data: query
    });
};

//获取指定博客分类的最近5条文章标题
export const blogClassTitleList5 = query => {
    return request({
        url: '/blogClassTitleList5',
        method: 'post',
        data: query
    });
};

//根据id获取博客详情
export const getBlogFromId = query => {
    return request({
        url: '/getBlogFromId',
        method: 'post',
        data: query
    });
};
//获取最近发表的5篇blog

export const getBlogList = query => {
    return request({
        url: '/getBlogList',
        method: 'post',
        data: query
    });
};