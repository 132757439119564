import * as Router from "vue-router";
import uploadMusic from "../pages/uploadMusic.vue";
import Home from "../pages/home.vue";
import about_name from "../pages/about.vue";
import shici from "../pages/shici.vue";
import blog from "../pages/blog.vue";
import writeBlog from "../pages/writeBlog.vue";
import blogDetails from "../pages/blogDetails.vue"

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/uploadMusic",
    component: uploadMusic,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/about",
    component: about_name,
  },
  {
    path: "/shici",
    component: shici,
  },
  {
    path: "/blog",
    component: blog,
  },
  {
    path: "/writeBlog",
    component: writeBlog,
  },
  {
    path: "/blog/write",
    name: "/blog/write",
    component: writeBlog,
  },
  {
    path: "/blog/details",
    name: "/blog/details",
    component: blogDetails,
  },
];

export default Router.createRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: Router.createWebHistory(),
  routes, // `routes: routes` 的缩写
});
