import request from '../utils/request';
/**
 * 请求歌曲
 * @param {*} query 请求参数
 * @returns request
 */
export const get_music = query => {
    return request({
        url: '/get_music',
        method: 'post',
        params: query
    });
};
//新增活动请求
export const add_music = query => {
    return request({
        url: '/add_music',
        method: 'post',
        params: query
    });
};
/**
 * 歌曲搜索
 * @param {*} query 
 * @returns 
 */
export const music_search = query => {
    return request({
        url: '/music/search',
        method: 'post',
        data: query
    });
};


/**
 * 歌曲打分，赞或踩
 * @param {*} query 请求参数
 * @returns request
 */
export const music_grade = query => {
    console.log(query)
    return request({
        url: '/music/grade',
        method: 'post',
        data: query
    });
};

/**
 * 根据歌曲id获取歌词
 * @param {*} query 请求参数
 * @returns request
 */
export const music_get_lyric = query => {
    // console.log(query)
    return request({
        url: '/music/lyric',
        method: 'post',
        data: query
    });
};