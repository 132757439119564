import { ElUpload, ElButton, ElMessage } from "element-plus";
import { genFileId } from 'element-plus';
import { reactive, ref, toRaw } from "vue";
export default {
  name: "uploadMusic",
  components: {
    ElUpload,
    ElButton
  },

  setup() {
    //用户填写的歌曲相关信息
    let music_data = reactive({
      music_name: '',
      music_singer: ''
    }); //绑定的相关歌曲信息给el-upload组件搭载，便于提交

    let musicData = toRaw(music_data); //空响应变量绑定给up-upload节点，方便操作节点

    let uploadMusicFile = ref(null); //上传数据

    function upload() {
      console.log("点击了歌曲上传");
      musicData = toRaw(music_data);
      console.log(musicData);

      if (musicData.music_name == '' || musicData.music_singer == '') {
        ElMessage({
          showClose: true,
          message: "请填写歌曲基本信息",
          type: "error"
        });
        return;
      }

      uploadMusicFile.value.submit();
    } //成功上传回调


    function music_on_success() {
      console.log("成功上传回调");
      uploadMusicFile.value.clearFiles();
      music_data.music_name = null;
      music_data.music_singer = null;
      ElMessage({
        showClose: true,
        message: "上传成功！",
        type: "success"
      });
    } //上传前格式验证


    function beforeUpload(file) {
      const isAudio = file.type === "audio/mpeg" || file.type === "audio/wav" || file.type === "audio/wma" || file.type === "audio/ogg" || file.type === "audio/ape" || file.type === "audio/acc" || file.type === "audio/flac"; //mp3|wav|wma|ogg|ape|acc|flac

      const isLt50M = file.size / 1024 / 1024 < 50;
      let error_msg = "";

      if (!isAudio) {
        error_msg = "只能上传音频文件哦";
      }

      if (!isLt50M) {
        error_msg = "上传头像图片大小不能超过 2MB!";
      }

      if (!(isAudio && isLt50M)) {
        console.log("不符合上传条件");
        music_data.music_name = null;
        music_data.music_singer = null;
        ElMessage({
          showClose: true,
          message: error_msg,
          type: "error"
        });
      }

      return isAudio && isLt50M;
    } //覆盖选择


    function handleExceed(files) {
      uploadMusicFile.value.clearFiles();
      const file = files[0];
      file.uid = genFileId();
      uploadMusicFile.value.handleStart(file);
    } //setup暴露（vue3属性方法）


    return {
      music_data,
      musicData,
      uploadMusicFile,
      upload,
      music_on_success,
      beforeUpload,
      handleExceed
    };
  }

};